export default {
  token: "",
  showMsg: "",
  currentUser: null,
  payment: [],
  notification: [],
  investment: [],
  withdraw: [],
  config:null,
  theme:'light',
};
