export default {
  setUser(state, payload) {
    if (payload.config) {
      // set config
      state.config = payload.config;

      // delete config from payload
      delete payload["config"];
    }

    if (payload.isFlagged || !payload.isEnabled) {
      return;
    }

    if (payload.token) {
      //set token
      state.token = payload.token;

      // store token in persistent storage
      sessionStorage.setItem("token", payload.token);

      // delete token from payload
      delete payload["token"];
    }

    // update user
    state.currentUser = payload;

    // store user in persistent storage
    sessionStorage.setItem("user", JSON.stringify(payload));
  },
  loadUser(state) {
    const token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");

    state.currentUser = JSON.parse(user);
    state.token = token;
  },
  logOut(state) {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    state.currentUser = null;
    state.token = null;
  },

  showMsg(state, msg) {
    state.showMsg = msg;
  },

  updatePayment(state, payment) {
    state.payment = payment;
  },

  updateInvestment(state, investment) {
    state.investment = investment;
  },

  updateWithdraw(state, withdraw) {
    state.withdraw = withdraw;
  },

  updateConfig(state, config) {
    state.config = config;
  },

  updateNotification(state, notification) {
    state.notification = notification;
  },

  updateTheme(state, theme) {
    state.theme = theme;
  },
};
