<template>
  <div>
    <md-empty-state
      v-if="withdraw.length === 0"
      md-icon="library_books"
      md-label="No Pending Withdraws"
      md-description="You currenly have no withdraw history"
    >
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>
    <div v-if="withdraw.length !== 0">
      <md-table
        v-model="searchResult"
        md-sort="timestamp"
        md-sort-order="asc"
        style="overflow:auto"
        md-card
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">Withdraw Management</h1>
          </div>

          <md-field md-clearable class="md-toolbar-section-end">
            <md-input
              placeholder="Search by email..."
              v-model="search"
              @input="findByEmail"
            />
          </md-field>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="No withdraw found"
          :md-description="
            `No withdraw found for this '${search}' user. Try a different email.`
          "
        >
        </md-table-empty-state>
        <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

        <md-table-row slot="md-table-row" slot-scope="{ item }">

           <md-table-cell md-label="Investment" md-sort-by="investmentRef">{{
            item.investmentRef
          }}</md-table-cell>
          
          <md-table-cell md-label="Email" md-sort-by="email"
            >{{ item.email || item.uid }}
          </md-table-cell>
          <md-table-cell md-label="Amount" md-sort-by="amount"
            >{{ item.amount | money }}
          </md-table-cell>
         

          <md-table-cell md-label="Status" md-sort-by="isEnabled">
            <!-- Accept -->
            <md-button
              @click="
                updateUserState(item._id, {
                  id: item._id,
                  update: { status: 'approved' },
                })
              "
              v-bind:style="[GreenStyle]"
              class="md-dense md-raised  md-primary"
              >Approved</md-button
            >
            <br />
            <!-- Decline -->
            <md-button
              @click="
                updateUserState(item._id, {
                  id: item._id,
                  update: { status: 'declined' },
                })
              "
              v-bind:style="[RedStyle]"
              class="md-dense md-raised  md-primary"
              >Decline</md-button
            >
          </md-table-cell>

          <md-table-cell md-label="">
            <md-progress-spinner
              v-if="item.progress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<style scoped>
.md-button {
  color: #fff !important;
}
</style>

<script>
import Http from "../../helpers/http";
import UTILS from "../../helpers/utils";
import { HOST } from "../../store/global";

const searchByEmail = (list, arg) => {
  if (arg) {
    return list.filter((e) =>
      e.email.toLowerCase().includes(arg.toLowerCase())
    );
  }
  return list;
};

export default {
  name: "withdraws",
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), "") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
  },
  data() {
    return {
      search: "",
      user: this.$store.state.currentUser,
      searchResult: [],
      withdraw: [],
      GreenStyle: {
        background: "teal",
      },
      RedStyle: {
        background: "red",
      },
      InfoStyle: {
        background: "#448aff",
      },
    };
  },
  methods: {
    /** SEARCH TABLE */
    findByEmail() {
      this.searchResult = searchByEmail(this.withdraw, this.search);
    },

    /** GET DEPOSITS */
    async getDeposits() {
      const url = HOST + "/withdraw/list";
      const req = await Http({ method: "GET", url });
      if (req.status) {
        this.withdraw = req.data.map((e) => {
          return { ...e, progress: false };
        });
        this.searchResult = this.withdraw;
      }
    },

    updateProgress(id, state) {
      this.searchResult.find((e) => e._id === id).progress = state;
    },

    /** ON User Status Change */
    async updateUserState(id, arg) {
      const url = HOST + "/withdraw/update";

      this.updateProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },
  },

  mounted() {
    this.getDeposits();
  },
};
</script>
