<template>
  <div>
    <md-empty-state
      v-if="investments.length === 0"
      md-icon="timeline"
      md-label="No Investment Yet!"
      md-description="You currently have no active investment"
    >
      <button
        class="btn btn-primary"
        style="color:#fff;text-decoration:none"
        @click="startPlan"
      >
        Start Investment
      </button>
    </md-empty-state>

    <!-- Table -->
    <md-table
      v-if="investments.length !== 0"
      v-model="investments"
      md-sort="name"
      md-sort-order="asc"
      md-card
    >
      <md-table-toolbar>
        <h1 class="md-title">Investments</h1>
      </md-table-toolbar>

      <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Plan" md-sort-by="plan">
          {{ item.plan.name }}</md-table-cell
        >
        <md-table-cell md-label="Currency">USD </md-table-cell>
        <md-table-cell md-label="Amount" md-sort-by="principal"
          >{{ item.principal | money }}
        </md-table-cell>
        <md-table-cell md-label="Cumulative Interest" md-sort-by="profit"
          >{{ item.profit | money }}
        </md-table-cell>
        <md-table-cell md-label="Earnings" md-sort-by="earned"
          >{{ item.earned | money }}
        </md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">{{
          item.status
        }}</md-table-cell>

        <md-table-cell md-label="Withdraw">
          <p v-if="!item.canWithdraw && !item.progress">Not Avaliable</p>
          <md-progress-spinner
            v-if="item.progress"
            :md-diameter="20"
            :md-stroke="3"
            md-mode="indeterminate"
          />
          <md-button
            v-if="item.canWithdraw && !item.progress"
            style="color:#fff"
            @click="
              () => {
                investment = item;
                wallerDialog = true;
              }
            "
            class="md-dense md-raised  md-primary"
            >Request Withdrawal</md-button
          >
        </md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="date">{{
          item.timestamp | date
        }}</md-table-cell>
      </md-table-row>
    </md-table>

    <center v-if="investments.length !== 0" style="margin-top:50px">
      <button
        class="btn btn-primary"
        style="color:#fff;text-decoration:none"
        @click="startPlan"
      >
        Start Investment
      </button>
    </center>

    <md-dialog :md-active.sync="showPlan">
      <md-content class="md-scrollbar">
        <section class="pricing">
          <center>
            <md-button
              @click="showPlan = false"
              class="md-icon-button md-raised closeBtn"
            >
              <md-icon>close</md-icon>
            </md-button>
          </center>

          <Plan />
        </section>
      </md-content>
    </md-dialog>

    <!-- DIALOG BOX -->
    <div>
      <md-dialog :md-active.sync="wallerDialog">
        <md-dialog-title>Bitcoin Wallet Address</md-dialog-title>
        <div style="padding:20px">
          <md-field>
            <label>Enter wallet address here</label>
            <md-input v-model="wallet" maxlength="60"></md-input>
          </md-field>
        </div>
        <md-dialog-actions>
          <md-button class="md-primary" @click="wallerDialog = false"
            >Cancel</md-button
          >
          <md-button class="md-primary" @click="withdraw()">Procced</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.md-content {
  overflow: auto;
}

.closeBtn {
  padding-left: 0px;
  margin-left: 0px;
}
</style>
<script>
import Http from "../../helpers/http";
import UTILS from "../../helpers/utils";
import { HOST } from "../../store/global";
import Plan from "../../components/plan";

export default {
  name: "Investment",
  components: { Plan },
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), " ") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    date: function(value) {
      return new Date(Number(value)).toLocaleDateString();
    },
  },
  data() {
    return {
      wallerDialog: false,
      showPlan: false,
      wallet: "",
      qrcode:"",
      walletAddress:"",
      investment: null,
      investments: this.$store.state.investment.map((e) => {
        return { ...e, progress: false };
      }),
    };
  },
  methods: {
    startPlan(){
     this.$router.push("start-plan");
    },
    async withdraw() {
      if ((!this.wallet || !this.investment) && this.investment.earned > 0) {
        return;
      }

      this.wallerDialog = false;

      const url = HOST + "/withdraw/request";

      this.updateProgress(this.investment._id, true);

      const req = await Http({
        method: "POST",
        url,
        body: {
          investmentRef: this.investment._id,
          wallet: `${this.wallet}`.trim(),
        },
      });
      this.updateProgress(this.investment._id, false);

      if (!req.status) {
        return this.showMsg(req.message || "Withdraw failed");
      }
      this.$store.dispatch("getInvestment");

      this.showMsg("Withdraw is under going processing");
      this.investment = null;
    },

    updateProgress(id, state) {
      this.investments.find((e) => e._id === id).progress = state;
    },

    async getInvestment() {
      const investment = await Http({
        url: `${HOST}/investment/list/true`,
        method: "GET",
        body: null,
      });
      if (!investment.status) {
        return [];
      }
      const data = investment.data.map((e) => {
        //   e.earned= 1000;
        const canWithdraw = Number(e.earned) > 0;
        return { ...e, progress: false, canWithdraw };
      });
      this.investments = data;

      return data;
    },

    async getConfig(){
const config = await Http({
        url: `${HOST}/config/`,
        method: "GET",
        body: null,
      });
      if (!config.status) {
        return {};
      }

      this.walletAddress = config.data.wallet;
      this.qrcode = config.data.qrcode;

      return config.data;
    },

    /**
     * Show Snack Bar
     */
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },

    /**
     * Close snakbar
     */
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },

  async mounted() {
    await this.getInvestment();
    this.$store.dispatch("getInvestment");
  },
};
</script>
