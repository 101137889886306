<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-4">
        <md-card md-with-hover>
          <md-card-header>
            <div class="md-title">Email Notification</div>
            <div class="md-subhead">
              Send directy inApp and email to user
            </div>
          </md-card-header>
          <hr style="background:grey !important" />
          <md-card-content>
            <div class="form-group">
              <label>Subject (Optional) default to company name</label>
              <input
                v-model="notification.subject"
                class="form-control"
                placeholder="Enter Subject"
                type="text"
              />
            </div>

            <div class="form-group">
              <label>Title*</label>
              <input
                v-model="notification.title"
                class="form-control"
                placeholder="Enter Title"
                type="text"
                required
              />
            </div>

            <div class="form-group">
              <label>Receipt Email*</label>
              <input
                v-model="notification.email"
                class="form-control"
                placeholder="Enter Email Address"
                type="email"
                required
              />
            </div>

            <md-field :md-theme="theme">
              <label>Enter Messge</label>

              <md-textarea v-model="notification.desc" required></md-textarea>
            </md-field>
          </md-card-content>

          <center>
            <md-progress-spinner
              v-if="progress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              v-if="!progress"
              @click="send"
              style="color:#fff"
              class="md-raised md-primary"
              >Send Notification</md-button
            >
          </center>
          <br />
        </md-card>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-8">
        <md-card md-with-hover>
          <md-card-header>
            <div class="md-title">Email History</div>
            <div class="md-subhead">
              Get list of users email history
            </div>
          </md-card-header>
          <hr style="background:grey !important" />
          <md-card-content>
            <div class="form-group">
              <label>Receipt Email*</label>
              <input
                v-model="email"
                class="form-control"
                placeholder="Enter Email Address"
                type="email"
                required
              />
            </div>
          </md-card-content>

          <center>
            <md-progress-spinner
              v-if="progress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              v-if="!progress"
              @click="find"
              style="color:#fff"
              class="md-raised md-primary"
              >Show History</md-button
            >
          </center>
          <br />

          <!-- TABLE -->
          <div v-if="mails.length !== 0">
            <md-table
              v-model="searchResult"
              md-sort="timestamp"
              md-sort-order="asc"
              style="overflow:auto"
              md-card
            >
              <md-table-toolbar>
                <div class="md-toolbar-section-start">
                  <h1 class="md-title">Mails</h1>
                </div>

                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input
                    placeholder="Search for mail by quote..."
                    v-model="search"
                    @input="findByMailByQuote"
                  />
                </md-field>
              </md-table-toolbar>

              <md-table-empty-state
                md-label="No Email found"
                :md-description="
                  `No email found for '${search}'. Try a different email.`
                "
              >
              </md-table-empty-state>

              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Subject"
                  >{{ item.subject }}
                </md-table-cell>

                <md-table-cell md-label="Title" md-sort-by="title"
                  >{{ item.title }}
                </md-table-cell>

                <md-table-cell md-label="Desc" md-sort-by="desc">{{
                  item.desc
                }}</md-table-cell>

                <md-table-cell md-label="Date" md-sort-by="timestamp">{{
                  item.timestamp | date
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Http from "../../helpers/http";
import { isEmpty } from "../../helpers/regex";
import { HOST } from "../../store/global";


const searchForMailByQoute = (list, arg) => {
  if (arg) {
    return list.filter((e) =>
      e.desc.toLowerCase().includes(arg.toLowerCase())
    );
  }
  return list;
};


export default {
  name: "notification",
  filters: {
    date: function(val) {
      return new Date(val).toUTCString();
    },
  },
  data() {
    return {
      progress: false,
      theme: "",
      search:"",
      email: "",
      mails: [],
      searchResult: [],
      notification: { subject: "", title: "", desc: "", email: "" },
    };
  },
  methods: {
       /** SEARCH TABLE */
    findByMailByQuote() {
      this.searchResult = searchForMailByQoute(this.mails, this.search);
    },
    async send() {
      if (!this.validateForm()) {
        return alert("Feilds with * are required");
      }
      this.progress = true;
      const url = HOST + "/notification/send";

      const req = await Http({ method: "POST", url, body: this.notification });
      this.progress = false;

      if (!req.status) {
        return alert("Error occured sending notification");
      }
      this.notification = { subject: "", title: "", desc: "", email: "" };
      alert("Notification Sent !!!");
    },

    async find() {
      if (!this.email) return;

      const url = HOST + "/notification/search/" + this.email.toLowerCase();
      const req = await Http({ method: "GET", url });
      if (req.status) {
        this.mails = req.data.map((e) => {
          return { ...e, progress: false,desc:e.desc.replace(/\$n/,"") };
        });
        this.searchResult = this.mails;
      }
    },
    created() {
      this.unsubcribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === "updateTheme") {
          this.theme = state.theme;
          console.log(this.theme, state.theme);
        }
      });
    },
    validateForm() {
      return (
        !isEmpty(this.notification.title) &&
        !isEmpty(this.notification.desc) &&
        !isEmpty(this.notification.email)
      );
    },
  },
};
</script>
