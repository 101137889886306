var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"row"},_vm._l((_vm.cards),function(card){return _c('div',{key:card.desc,staticClass:"col-lg-3 col-md-4 col-sm-12"},[_c('md-card',{class:[card.color],attrs:{"md-with-hover":""}},[(_vm.progress)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e(),_c('md-card-header',[_c('md-card-header-text',[_c('div',{staticClass:"md-title"},[_vm._v(_vm._s(card.title))]),_c('div',{staticClass:"md-subhead"},[_vm._v(_vm._s(card.desc))])]),_c('md-card-media',[_c('md-icon',{staticClass:"md-size-3x"},[_vm._v(_vm._s(card.icon))])],1)],1)],1)],1)}),0),_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"row"},[(_vm.users.length !== 0)?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('md-toolbar',{attrs:{"md-elevation":0}},[_c('span',{staticClass:"md-title"},[_vm._v("New Registrations Awaitng Approved")])]),_c('br'),_c('div',{staticStyle:{"height":"400px !important","overflow":"auto"}},_vm._l((_vm.users),function(user){return _c('md-card',{key:user.uid,staticStyle:{"margin-bottom":"20px"},attrs:{"md-with-hover":""}},[_c('md-card-header',[_c('div',{staticClass:"md-title"},[_vm._v(" "+_vm._s(user.firstName)+" "+_vm._s(user.lastName)+" ")])]),_c('md-card-content',[_c('ul',[_c('li',[_vm._v("Email: "+_vm._s(user.email))]),_c('li',[_vm._v("Trader: "+_vm._s(user.trader))]),_c('li',[_vm._v("Last Login Attemp: "+_vm._s(user.last_login))])])]),_c('md-card-actions',[(user.progress)?_c('md-progress-spinner',{attrs:{"md-diameter":20,"md-stroke":3,"md-mode":"indeterminate"}}):_vm._e(),(!user.progress)?_c('md-button',{staticClass:"amber",on:{"click":function($event){return _vm.updateUSerState(user.uid, {
                  email: user.email,
                  isFlagged:
                    'account approval failed, contact support for more info',
                })}}},[_vm._v("Decline Regitration")]):_vm._e(),(!user.progress)?_c('md-button',{staticClass:"teal",on:{"click":function($event){return _vm.updateUSerState(user.uid, {
                  email: user.email,
                  isFlagged: null,
                })}}},[_vm._v("Approve Registration")]):_vm._e()],1)],1)}),1)],1):_vm._e(),(_vm.verifications.length !== 0)?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('md-toolbar',{attrs:{"md-elevation":0}},[_c('span',{staticClass:"md-title"},[_vm._v("ID Card Verification")])]),_c('br'),_c('center',{staticStyle:{"height":"400px !important","overflow":"auto"}},_vm._l((_vm.verifications),function(item){return _c('md-card',{key:item._id,staticStyle:{"margin-bottom":"20px","width":"400px !important"},attrs:{"md-with-hover":""}},[_c('md-card-content',[_c('center',[_c('img',{attrs:{"src":item.image,"width":"200"}}),_c('br'),_c('br'),_c('span',[_vm._v("Email: "+_vm._s(item.email))]),_c('br'),_c('span',[_vm._v("Status: "+_vm._s(item.status))]),_c('br'),_c('br'),_c('br'),(item.progress)?_c('md-progress-spinner',{attrs:{"md-diameter":20,"md-stroke":3,"md-mode":"indeterminate"}}):_vm._e(),(!item.progress)?_c('md-button',{staticClass:"amber",on:{"click":function($event){return _vm.updateVerificationState(item._id, {
                    email: item.email,
                    status: 'Not Verified',
                  })}}},[_vm._v("Decline")]):_vm._e(),(!item.progress)?_c('md-button',{staticClass:"teal",on:{"click":function($event){return _vm.updateVerificationState(item._id, {
                    email: item.email,
                    status: 'Verified',
                  })}}},[_vm._v("Approve")]):_vm._e()],1)],1)],1)}),1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }