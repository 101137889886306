/*eslint no-useless-escape: "error"*/
/** Handles validation against null | undefined | 0 | Empty-Object
 *  ! does not validate number e.g (-1 will pass)
 * @param value any
 */
export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};



/** Validate email address */
export const isValidEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/;//eslint-disable-line



/** Validate string is integer and has no "." or special character */
export const isInteger = /^(?:[1-9]\d*|\d)$/;



/**  Validate arg is a consists of only alphabet A-Z and a-Z */
export const isValidAlphabet = /^[a-zA-Z ]*$/;



/** Validate name does not have any special character like "*", ".", ","... including white space */
export const isValidName = /^[a-zA-Z]{1,30}$/;


/** Validate password has min of 8 character */
export const isValidPassword = /^.{8,}$/;



/** Validate pin is four (4) digit and does not contain any string or special characters */
export const isValidPin = /^[0-9]{4,4}$/;



/** Validate  arg is a whitespace */
export const whiteSpace = /\s/g;



/**  Validate arg is a valid boolean TRUE | FALSE */
export const isBoolean = /^(true|false|1|0)$/;




/** Validate telephone or mobile number is a valid telephone number */
export const isValidPhone = /^\+[0-9]{8,18}$/;



// Validate arg is a pair or string
export const isPair = /^[a-z_]*$/;


/** Validate arg is a valid account number that has no string, special character */
export const isAccountNumber = /^[0-9]{10}$/;



/** Validate arg is a of valid money format that contain no string */
export const isMoney = /^\d{0,10}(\.\d{0,2}){0,1}$/;




export const isValidTransactionType = /^(PAYMENT|AIRTIME|DEPOSIT|WITHDRAW|TRANSFER|INVESTMENT)$/;
