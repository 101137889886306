<template>
  <div class="page-container md-layout-row">
    <md-app style="height: 100vh">
      <md-app-toolbar class="md-primary" md-elevation="0">
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!menuVisible"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <span style="color:#fff" class="md-title">{{ $route.name }}</span>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-persistent="mini">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span></span>

          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <md-list>
          <!-- Profile Header -->
          <div
            v-if="menuVisible"
            style="margin-left: 20px; margin-bottom: 10px"
          >
            <img
              class="avatar"
              :src="
                this.user
                  ? this.user.profileImg
                  : 'https://secure.gravatar.com/avatar/0b7b3a96e4c10c2e403d355a59d5b6b0?s=500&d=identicon'
              "
              alt="Avatar"
            />
            <br />
            <span class="name"
              >Admin {{ user.firstName }} {{ user.lastName }}</span
            >
            <br />
            <span class="email">{{ user.email }}</span>
          </div>
          <md-divider class="md-inset" style=" margin-bottom: 30%"></md-divider>
          <md-list-item
            v-for="route in routes"
            v-bind:key="route.name"
            v-bind:class="[$route.name === route.name ? 'active' : '']"
            @click="goto(route.location, route.name)"
          >
            <md-icon>{{ route.icon }}</md-icon>
            <span class="md-list-item-text">{{ route.name }}</span>
          </md-list-item>

          <md-list-item @click="logout()">
            <md-icon>login</md-icon>
            <span class="md-list-item-text">Logout</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content>
        <adminDashboard v-if="$route.name === 'Admin Panel'"> </adminDashboard>
        <adminDeposit v-if="$route.name === 'Deposit Management'">
        </adminDeposit>
        <afminNotification
          v-if="$route.name === 'Notifications'"
        ></afminNotification>
        <adminUsers v-if="$route.name === 'User Management'"></adminUsers>
        <adminWithdraw
          v-if="$route.name === 'Withdraw Management'"
        ></adminWithdraw>
        <adminInvestment
          v-if="$route.name === 'Investment Management'"
        ></adminInvestment>
         <config
          v-if="$route.name === 'Configuration'"
        ></config>
      </md-app-content>
    </md-app>
  </div>
</template>

<style scoped>
.active {
  color: blue;
}
.md-app {
  min-height: 350px;
  border: 1px solid rgba(#000, 0.12);
}
.name {
  color: #fff !important;
  font-size: 18px !important;
}
.email {
  color: #fff !important;
  font-size: 12px !important;
}
.md-drawer {
  width: 300px;
  max-width: calc(100vw - 125px);
}
.avatar {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  text-align: start;
}
</style>

<script>
// ADMIN COMPONETES
import adminDashboard from "./admin/dashboard";
import adminDeposit from "./admin/deposit";
import adminInvestment from "./admin/investment";
import afminNotification from "./admin/notification";
import adminUsers from "./admin/users";
import adminWithdraw from "./admin/withdraw";
import config from "./admin/config";
import { resetRouter } from "../router";

export default {
  name: "Home",
  components: {
    adminDashboard,
    adminDeposit,
    adminInvestment,
    afminNotification,
    adminUsers,
    adminWithdraw,config
  },
  data() {
    return {
      menuVisible: false,
      user: this.$store.state.currentUser,
      routes: [
        { name: "Admin Panel", location: "admin", icon: "dashboard" },
        {
          name: "User Management",
          location: "users",
          icon: "account_circle",
        },
        {
          name: "Investment Management",
          location: "investments",
          icon: "timeline",
        },
        {
          name: "Notifications",
          location: "notifications",
          icon: "message",
        },
        {
          name: "Deposit Management",
          location: "deposits",
          icon: "payment",
        },
        {
          name: "Withdraw Management",
          location: "withdraws",
          icon: "account_balance",
        },
        {
          name: "Configuration",
          location: "config",
          icon: "settings",
        },
      ],
    };
  },

  methods: {
    logout() {
      this.$store.commit("logOut");
      resetRouter();
      this.$router.replace("./");
    },
    goto(route, name) {
      if (route && name !== this.$route.name) {
        this.$router.push(route);
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>
