<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <md-card md-with-hover>
          <md-card-header>
            <div class="md-title">Modify Plan</div>
            <div class="md-subhead">
              Update investment plan
            </div>
          </md-card-header>
          <md-card-content>
            <div class="form-group">
              <label>Refrence</label>
              <input
                v-model="plan_ref"
                class="form-control"
                placeholder="Enter Ref"
                type="text"
              />
            </div>
            <div class="form-group">
              <label>New Plan*</label>
              <select
                v-model="new_plan"
                aria-placeholder="Select Plan"
                class="form-control"
              >
              <option value="">Select Plan</option>
                <option v-for="plan in PLAN" :value="plan.id" :key="plan.id">{{
                  plan.name
                }}</option>
              </select>
            </div>
          </md-card-content>

          <center>
            <md-progress-spinner
              v-if="plan_progress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              v-if="!plan_progress"
              @click="updatePlan()"
              v-bind:style="[InfoStyle]"
              class="md-raised md-primary"
              >Update Plan</md-button
            >
          </center>
          <br />
        </md-card>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <md-card md-with-hover>
          <md-card-header>
            <div class="md-title">Modify Investment</div>
            <div class="md-subhead">
              Update investment earing
            </div>
          </md-card-header>
          <md-card-content>
            <div class="form-group">
              <label>Refrence</label>
              <input
                v-model="ref"
                class="form-control"
                placeholder="Enter Ref"
                type="text"
              />
            </div>
            <div class="form-group">
              <label>Capital*</label>
              <input
                v-model="principal"
                class="form-control"
                placeholder="Enter Capital"
                type="number"
              />
            </div>
            <div class="form-group">
              <label>Profit*</label>
              <input
                v-model="profit"
                class="form-control"
                placeholder="Enter Amount"
                type="number"
              />
            </div>
            <div class="form-group">
              <label>Earned*</label>
              <input
                v-model="earned"
                class="form-control"
                placeholder="Enter Amount"
                type="number"
              />
            </div>
          </md-card-content>

          <center>
            <md-progress-spinner
              v-if="progress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              v-if="!progress"
              @click="updateInvestment()"
              style="color:#fff"
              class="md-raised md-primary"
              >Apply Changes</md-button
            >
          </center>
          <br />
        </md-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col">
        <!-- EMPTY STATE -->
        <md-empty-state
          v-if="investments.length === 0"
          md-icon="timeline"
          md-label="No Active Investment"
          md-description="There are currenly no active investment"
        >
        </md-empty-state>

        <!-- TABLE -->
        <div v-if="investments.length !== 0">
          <md-table
            v-model="searchResult"
            md-sort="timestamp"
            md-sort-order="asc"
            style="overflow:auto"
            md-card
          >
            <md-table-toolbar>
              <div class="md-toolbar-section-start">
                <h1 class="md-title">Investments</h1>
              </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input
                  placeholder="Search by email..."
                  v-model="search"
                  @input="findByEmail"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-empty-state
              md-label="No deposit found"
              :md-description="
                `No deposit found for this '${search}' user. Try a different email.`
              "
            >
            </md-table-empty-state>
            <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ref">{{ item.ref }} </md-table-cell>

              <md-table-cell md-label="Email" md-sort-by="email"
                >{{ item.email }}
              </md-table-cell>

              <md-table-cell md-label="Plan" md-sort-by="plan">{{
                item.plan.name
              }}</md-table-cell>

              <md-table-cell md-label="Principal" md-sort-by="principal"
                >{{ item.principal | money }}
              </md-table-cell>

              <md-table-cell md-label="Profit" md-sort-by="profit">{{
                item.profit | money
              }}</md-table-cell>

              <md-table-cell md-label="Earned" md-sort-by="earned">{{
                item.earned | money
              }}</md-table-cell>

              <md-table-cell md-label="Status" md-sort-by="status">{{
                item.status
              }}</md-table-cell>

              <md-table-cell md-label="Start Date" md-sort-by="startAt">{{
                item.startAt | date
              }}</md-table-cell>

              <md-table-cell md-label="Status" md-sort-by="isEnabled">
                <!-- Accept -->
                <md-button
                  @click="
                    updateUserState(item.uid, {
                      ref: item.ref,
                      update: {
                        status: item.status === 'hold' ? 'active' : 'hold',
                      },
                    })
                  "
                  v-bind:style="[
                    item.status === 'hold' ? GreenStyle : InfoStyle,
                  ]"
                  class="md-dense md-raised  md-primary"
                  >{{
                    item.status === "hold"
                      ? "Open Investment"
                      : "Hold Investment"
                  }}</md-button
                >
                <br />
                <!-- Decline -->
                <md-button
                  v-if="item.status !== 'closed'"
                  @click="
                    updateUserState(item.uid, {
                      ref: item.ref,
                      update: { status: 'closed' },
                    })
                  "
                  v-bind:style="[RedStyle]"
                  class="md-dense md-raised  md-primary"
                  >Close</md-button
                >
              </md-table-cell>

              <md-table-cell md-label="">
                <md-progress-spinner
                  v-if="item.progress"
                  :md-diameter="20"
                  :md-stroke="3"
                  md-mode="indeterminate"
                />
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.md-button {
  color: #fff !important;
}
</style>

<script>
import Http from "../../helpers/http";
import UTILS from "../../helpers/utils";
import { HOST, PLAN } from "../../store/global";

const searchByEmail = (list, arg) => {
  if (arg) {
    return list.filter((e) =>
      e.email.toLowerCase().includes(arg.toLowerCase())
    );
  }
  return list;
};

export default {
  name: "investment",
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), "") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    plan: function(value) {
      const plan = PLAN.find(
        (e) => e.data.filter((p) => p.id === value).length > 0
      );

      return plan.data.find((e) => e.id === value).name;
    },
    date: function(val) {
      return new Date(val).toUTCString();
    },
  },
  data() {
    return {
      search: "",
      ref: "",
      plan_ref: "",
      new_plan: "",
      profit: "",
      earned: "",
      principal: "",
      progress: false,
      plan_progress: false,
      user: this.$store.state.currentUser,
      searchResult: [],
      investments: [],
      GreenStyle: {
        background: "teal",
      },
      RedStyle: {
        background: "red",
      },
      InfoStyle: {
        background: "#448aff",
      },
      PLAN: PLAN[0].data,
    };
  },
  methods: {
    /** SEARCH TABLE */
    findByEmail() {
      this.searchResult = searchByEmail(this.deposit, this.search);
    },

    /** GET INVESTMENTS */
    async getInvestments() {
      const url = HOST + "/investment/list";
      const req = await Http({ method: "GET", url });
      if (req.status) {
        this.investments = req.data.map((e) => {
          return { ...e, progress: false };
        });
        this.searchResult = this.investments;
      }
    },

    updateProgress(id, state) {
      this.searchResult.find((e) => e.uid === id).progress = state;
    },

    /** ON Investment Status Change */
    async updateUserState(id, arg) {
      const url = HOST + "/investment/update";

      this.updateProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },

    /** ON Investment */
    async updateInvestment() {
      const url = HOST + "/investment/update";

      const payload = {};

      if (!this.ref) return;

      if (this.earned) payload.earned = Number(this.earned);
      if (this.profit) payload.profit = Number(this.profit);
      if (this.principal) payload.principal = Number(this.principal);

      if (Object.keys(payload).length < 1) return;

      this.progress = true;

      const req = await Http({
        method: "POST",
        url,
        body: {
          ref: this.ref,
          update: payload,
        },
      });
      this.progress = false;

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },

    /** Update Plan */
    async updatePlan() {
      const url = HOST + "/investment/update";

      if (!this.new_plan) return;

      const plan = PLAN[0].data.find((e) => e.id === this.new_plan);

      if (!plan) return alert("Invalid plan");

      const payload = { plan };

      if (Object.keys(payload).length < 1) return;

      this.plan_progress = true;

      const req = await Http({
        method: "POST",
        url,
        body: {
          ref: this.plan_ref,
          update: payload,
        },
      });
      this.plan_progress = false;

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },
  },

  mounted() {
    this.getInvestments();
  },
};
</script>
