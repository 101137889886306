import store from "../store";

export async function canEnterComponent(to, from, next) {
  // console.clear();

  try {
    const token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");

    const currentUser = JSON.parse(user);

    if (token && user && !currentUser.isFlagged && currentUser.isEnabled) {
      store.commit("loadUser");

      return next();
    } else
      return next({
        path: "login",
      });
  } catch (error) {
    console.log(error);
  }
}

export async function canEnterAsAdminComponent(to, from, next) {
  // console.clear();
  try {
    const user = sessionStorage.getItem("user");

    const currentUser = JSON.parse(user);

    if (currentUser && currentUser.isAdmin) {
      store.commit("loadUser");
      return next();
    } else {
      return next({
        path: "login",
      });
    }
  } catch (error) {
    console.log(error);
  }
}
