import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuelidate from 'vuelidate'

import { MdButton, MdProgress,MdSnackbar,
  MdRipple,
  MdIcon,MdBadge} from 'vue-material/dist/components'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import './style.scss';



import ToggleButton from 'vue-js-toggle-button'



Vue.config.silent = true
Vue.config.productionTip = false

Vue.use(VueMaterial)
Vue.use(MdButton)
Vue.use(MdProgress)
Vue.use(MdSnackbar)
Vue.use(MdIcon)
Vue.use(MdBadge)
Vue.use(MdRipple)

Vue.use(Vuelidate)

Vue.use(ToggleButton)


new Vue({
  router,
  store, 
  render: h => h(App)
}).$mount('#app')
