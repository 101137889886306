<template>
  <div>
    <md-empty-state
      v-if="payment.length===0"
      md-icon="history"
      md-label="No Deposit"
      md-description="You currently have no deposit history"
    >
    </md-empty-state>
    <!-- Market -->
    <md-table v-if="payment.length!==0" v-model="payment" md-sort="name" md-sort-order="asc" md-card>
      <md-table-toolbar >
        <h1 class="md-title">Deposits</h1>
      </md-table-toolbar>

      <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Plan" md-sort-by="plan">
          {{ item.plan | plan }}</md-table-cell
        >
        <md-table-cell md-label="Currency" md-sort-by="name"
          >USD
        </md-table-cell>
        <md-table-cell md-label="Amount" md-sort-by="amount"
          >{{ item.amount | money }}
        </md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">{{
          item.status
        }}</md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="date">{{
          item.created_on
        }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import UTILS from "../../helpers/utils";
import { PLAN } from "../../store/global";

export default {
  name: "deposit",
  filters: {
    money: function(value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), " ") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    plan: function(value) {
      const plan = PLAN.find(
        (e) => e.data.filter((p) => p.id === value).length > 0
      );

      return plan.data.find((e) => e.id === value).name;
    },
  },
  data() {
    return {
      payment: this.$store.state.payment,
    };
  },
  async mounted() {
    this.$store.dispatch("getPayment");
  },
};
</script>
