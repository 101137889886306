<template>
  <div class="container-fluid" style="overflow:auto">
    <!-- Account Summary -->
    <div class="row">
      <div
        class="col-lg-3 col-md-4 col-sm-12"
        v-for="card in cards"
        v-bind:key="card.desc"
      >
        <md-card v-bind:class="[card.color]" md-with-hover>
          <md-progress-bar
            v-if="progress"
            md-mode="indeterminate"
          ></md-progress-bar>

          <md-card-header>
            <md-card-header-text>
              <div class="md-title">{{ card.title }}</div>
              <div class="md-subhead">{{ card.desc }}</div>
            </md-card-header-text>

            <md-card-media>
              <md-icon class="md-size-3x">{{ card.icon }}</md-icon>
            </md-card-media>
          </md-card-header>
        </md-card>
      </div>
    </div>

    <br />
    <br />
    <br />

    <!-- ACCOUNT MANAGEMENT -->
    <div class="row">
      <!-- Registration Approval -->
      <div v-if="users.length !== 0" class="col-lg-6 col-md-6 col-sm-12">
        <md-toolbar :md-elevation="0">
          <span class="md-title">New Registrations Awaitng Approved</span>
        </md-toolbar>
        <br />
        <div style="height:400px !important;overflow:auto">
          <md-card
            md-with-hover
            v-for="user of users"
            v-bind:key="user.uid"
            style="margin-bottom:20px"
          >
            <md-card-header>
              <div class="md-title">
                {{ user.firstName }} {{ user.lastName }}
              </div>
            </md-card-header>

            <md-card-content>
              <ul>
                <li>Email: {{ user.email }}</li>
                <li>Trader: {{ user.trader }}</li>
                <li>Last Login Attemp: {{ user.last_login }}</li>
              </ul>
            </md-card-content>

            <md-card-actions>
              <md-progress-spinner
                v-if="user.progress"
                :md-diameter="20"
                :md-stroke="3"
                md-mode="indeterminate"
              />
              <md-button
                v-if="!user.progress"
                class="amber"
                @click="
                  updateUSerState(user.uid, {
                    email: user.email,
                    isFlagged:
                      'account approval failed, contact support for more info',
                  })
                "
                >Decline Regitration</md-button
              >
              <md-button
                v-if="!user.progress"
                class="teal"
                @click="
                  updateUSerState(user.uid, {
                    email: user.email,
                    isFlagged: null,
                  })
                "
                >Approve Registration</md-button
              >
            </md-card-actions>
          </md-card>
        </div>
      </div>

      <!-- ID Card Verification -->
      <div
        v-if="verifications.length !== 0"
        class="col-lg-6 col-md-6 col-sm-12"
      >
        <md-toolbar :md-elevation="0">
          <span class="md-title">ID Card Verification</span>
        </md-toolbar>
        <br />

        <center style="height:400px !important;overflow:auto">
          <md-card
            md-with-hover
            v-for="item of verifications"
            v-bind:key="item._id"
            style="margin-bottom:20px; width:400px !important"
          >
            <md-card-content>
              <center>
                <img :src="item.image" width="200" />

                <br />
                <br />

                <span>Email: {{ item.email }}</span>
                <br />
                <span>Status: {{ item.status }}</span>
                <br />
                <br />
                <br />

                <md-progress-spinner
                  v-if="item.progress"
                  :md-diameter="20"
                  :md-stroke="3"
                  md-mode="indeterminate"
                />
                <md-button
                  v-if="!item.progress"
                  class="amber"
                  @click="
                    updateVerificationState(item._id, {
                      email: item.email,
                      status: 'Not Verified',
                    })
                  "
                  >Decline</md-button
                >

                <md-button
                  v-if="!item.progress"
                  class="teal"
                  @click="
                    updateVerificationState(item._id, {
                      email: item.email,
                      status: 'Verified',
                    })
                  "
                  >Approve</md-button
                >
              </center>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
  </div>
</template>

<style scoped>
.green {
  background: #43a047 !important;
}
.blue {
  background: #1e88e5 !important;
}
.teal {
  background: teal !important;
}
.amber {
  background: darkgoldenrod !important;
}
</style>

<script>
import Http from "../../helpers/http";
import { HOST } from "../../store/global";
import UTILS from "../../helpers/utils";

export default {
  name: "Dashboard",

  data() {
    return {
      progress: false,
      users: [],
      verifications: [],
      cards: [
        {
          title: "0",
          desc: "Total Users",
          icon: "person",
          color: "blue",
        },
        {
          title: "0.00",
          desc: "Total Amount Invested",
          icon: "attach_money",
          color: "green",
        },
        {
          title: "0.00",
          desc: "Total Investment Count",
          icon: "attach_money",
          color: "teal",
        },

        {
          title: "0.00",
          desc: "Total Withdrawal",
          icon: "payments",
          color: "md-accent",
        },
      ],
    };
  },
  async mounted() {
    this.getUsers();
    this.getInvestments();
    this.getWithdraws();
    this.getIDVerifications();
  },
  methods: {
    /** UPDATE VERIFICATION PROGRESS BAR STATE */
    updateVerificationItemProgress(id, state) {
      this.verifications.find((e) => e._id === id).progress = state;
    },

    /** UPDATE USER REGISTRATION PROGRESS BAR STATE */
    updateUserItemProgress(id, state) {
      this.users.find((e) => e.uid === id).progress = state;
    },

    /** RUN USER UPDATE */
    async updateUSerState(id, arg) {
      const url = HOST + "/user/update";

      this.updateUserItemProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateUserItemProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      this.users = this.users.filter((e) => e.uid !== id);
    },

    /** RUN VERIFICATION UPDATE */
    async updateVerificationState(id, arg) {
      const url = HOST + "/verification/id_card/update";

      this.updateVerificationItemProgress(id, true);

      const req = await Http({ method: "POST", url, body: arg });
      this.updateVerificationItemProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      this.verifications = this.verifications.filter((e) => e._id !== id);
    },

    /** GET ID VERIFICATIONS */
    async getIDVerifications() {
      this.progress = true;
      const url = HOST + "/verification/id_card/list";
      const req = await Http({ method: "GET", url });
      this.progress = false;
      if (req.status) {
        this.verifications = req.data
          .filter((e) => e.status === "Pending Verification")
          .map((e) => {
            return { ...e, progress: false };
          });
      }
    },

    /** GET USERS */
    async getUsers() {
      this.progress = true;
      const url = HOST + "/user/list";
      const req = await Http({ method: "GET", url });
      this.progress = false;
      if (req.status) {
        this.users = req.data
          .filter(
            (e) => e.isFlagged && e.isFlagged.includes("failed") === false
          )
          .map((e) => {
            return { ...e, progress: false };
          });
        this.cards[0].title = req.data.length;
      }
    },
    
    /** GET INVESTMENTS */
    async getInvestments() {
      this.progress = true;

      const url = HOST + "/investment/list";
      const req = await Http({ method: "GET", url });
      this.progress = false;

      if (req.status) {
        this.cards[2].title = req.data.length;
        this.cards[1].title = UTILS.moneyFormat(
          req.data
            .map((e) => e.principal)
            .reduce((p, c) => {
              return Number(p) + Number(c);
            }, 0) || 0
        );
      }
    },

        /** GET INVESTMENTS */
    async getWithdraws() {
      this.progress = true;

      const url = HOST + "/withdraw/list";
      const req = await Http({ method: "GET", url });
      this.progress = false;

      if (req.status) {
        this.cards[3].title = UTILS.moneyFormat(
          req.data
            .map((e) => e.amount)
            .reduce((p, c) => {
              return Number(p) + Number(c);
            }, 0) || 0
        );
      }
    },
 
  },
};
</script>
